document.addEventListener("DOMContentLoaded", () => {
  // .header-btnがクリックされたときの処理
  const headerBtn = document.querySelectorAll(".header-btn");
  const headerSp = document.querySelectorAll(".header-sp");

  headerBtn.forEach((btn) => {
    btn.addEventListener("click", () => {
      headerBtn.forEach((btn) => btn.classList.toggle("open"));
      headerSp.forEach((sp) => sp.classList.toggle("open"));
    });
  });

  // .header-sp内のaタグがクリックされたときの処理
  const headerSpLinks = document.querySelectorAll(".header-sp a");

  headerSpLinks.forEach((link) => {
    link.addEventListener("click", () => {
      headerBtn.forEach((btn) => btn.classList.remove("open"));
      headerSp.forEach((sp) => sp.classList.remove("open"));
    });
  });
});
